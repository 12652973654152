import { Box } from "@chakra-ui/react";
import React from "react";
import { useContext } from "react";
import { RefContext } from "../Context/RefContext";
import styles from "../Styles/Projects.module.css";
import { motion } from "framer-motion";
import jiomartImg from "./Images/jiomart.png";

export const Project = () => {
  const { projectRef } = useContext(RefContext);
  return (
    <div ref={projectRef} className={styles.containerTwo}>
      <section id={styles.projects}>
        <div>
          <motion.h2
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: false, amount: 1 }}
            transition={{
              duration: 0.5,
            }}
            className={"sectionHeading"}
            data-outline="Projects"
          >
            Projects
          </motion.h2>
          <div className={styles.containerThree}>
            <motion.div
              className={styles.item}
              initial={{ x: 70, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: false, amount: 0.1 }}
              transition={{
                duration: 3.5,
                type: "spring",
                stiffness: 300,
              }}
            >
              <div className={styles.left}>
                <div className={styles.img}>
                  <img src="https://i.imgur.com/qzZ86v4.jpg" alt="" />
                </div>
              </div>
              <div className={styles.right}>
                <h2 className={styles.projectTitle}>Full-stack Blissworld Clone </h2>

                <p className={styles.projectDesc}>
                  Build clone of blissworld in 7 days along with a team 4
                  members.
                  <h3>Features</h3>
                  <ul style={{ marginLeft: "30px" }}>
                    <li>Login</li>
                    <li>Signup</li>
                    <li>Product page</li>
                    <li>Product detail page</li>
                    <li>Cart Page</li>
                    <li>Checkout Page</li>
                    <li>Order page</li>
                  </ul>
                  <br/>
                  <h2><b><i> Tech Stack :</i></b> Reactjs | ChakraUI | Nodejs | Express | MongoDB | Mongoose</h2>
                  <br/>
                  <h2>
                   <b><i> Area of Responsibility :</i></b> Developed the cart page with all CRUD properties of adding product to cart , update and delete products from cart.
                  </h2>
                </p>
                <div className={styles.buttons}>
                  <a
                    href="https://github.com/krishnachaturvedi1901/Blissworld_MERN_Fullstack_Clone"
                    target="_blank"
                    className={styles.primaryBtn}
                  >
                    GitHub
                  </a>
                  <a
                    href="https://blissworld-fullstack-clone.netlify.app"
                    target="_blank"
                    className={styles.primaryBtn}
                  >
                    Preview{" "}
                  </a>
                  <a
                    href="https://blissworld-mern-fullstack-clone.hashnode.dev/mern-full-stack-clone"
                    target="_blank"
                    className={styles.primaryBtn}
                  >
                  Blog{" "}
                 </a>
                 <a
                   href="https://blissworld-mern-fullstack-clone.hashnode.dev/mern-full-stack-clone"
                   target="_blank"
                   className={styles.primaryBtn}
                 >
                   Demo{" "}
                 </a>
                </div>
              </div>
            </motion.div>
            {/* ========================================================================================================================================= */}
            <motion.div
              className={styles.item}
              initial={{ x: -70, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: false, amount: 0.1 }}
              transition={{
                duration: 3.5,
                type: "spring",
                stiffness: 300,
              }}
              // transition={{ delay: 0.1 }}
            >
              <div className={styles.left}>
                <div className={styles.img}>
                  <img src="https://cdn.hashnode.com/res/hashnode/image/upload/v1670347682821/AL5b9trwM.png?auto=compress,format&format=webp" alt="" />
                </div>
              </div>
              <div className={styles.right}>
                <h2 className={styles.projectTitle}>Bohemian Traders E-commerce Clone</h2>

                <p className={styles.projectDesc}>
                  We worked in a team of 4 members during construct week to work on a bohemian traders website clone and created a fully responsive CRUD-based react.js women clothing e-commerce application in a week.
                  <h2>Features</h2>
                  <ul style={{ marginLeft: "30px" }}>
                    <li>Login</li>
                    <li>Signup</li>
                    <li>Home page</li>
                    <li>Product page</li>
                    <li>Product detail page</li>
                    <li>Cart Page</li>
                    <li>Checkout Page</li>
                    <li>Order page</li>
                  </ul>
                  <br/>
                  <h2>
                   <b><i>Tech Stack :</i></b>  Jsx | Reactjs | ChakraUI | Redux | React-routing | JSON-Server | API{" "}
                  </h2>
                  <h2>
                  <br/>
                   <b><i>Area of Responsibility :</i></b>  
                   Worked on the product page and created it up to a fully responsive page including filter sorting functionality using redux.
                  </h2>
                  <br/>
                </p>
                <div className={styles.buttons}>
                  <a
                    href="https://github.com/krishnachaturvedi1901/Bohemiantraders-website-clone"
                    target="_blank"
                    className={styles.primaryBtn}
                  >
                    Github
                  </a>
                  <a
                    href="https://bohemian-clone-team9.netlify.app"
                    target="_blank"
                    className={styles.primaryBtn}
                  >
                    Preview{" "}
                  </a>
                  <a
                    href="https://krishnachaturvedi.hashnode.dev/bohemiantraderscom-website-clone"
                    target="_blank"
                    className={styles.primaryBtn}
                  >
                    Blog{" "}
                  </a>
                </div>
              </div>
            </motion.div>
            {/* ========================================================================================================================================= */}

            <motion.div
              className={styles.item}
              initial={{ x: 70, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              viewport={{ once: false, amount: 0.1 }}
              transition={{
                duration: 3.5,
                type: "spring",
                stiffness: 300,
              }}
              // transition={{ delay: 0.1 }}
            >
              <div className={styles.left}>
                <div className={styles.img}>
                  <img src={jiomartImg} alt="olx" />
                </div>
              </div>
              <div className={styles.right}>
                <h2 className={styles.projectTitle}>Reliance Digital clone</h2>

                <p className={styles.projectDesc}>
                  We worked in a team during 2nd construct week to work on a Reliance Digital website clone a javascript-based e-commerce application.
                  <h2>Features</h2>
                  <ul style={{ marginLeft: "30px" }}>
                  <li>Login</li>
                  <li>Signup</li>
                  <li>Home page</li>
                  <li>Product page</li>
                  <li>Product detail page</li>
                  <li>Cart Page</li>
                  <li>Checkout Page</li>
                  <li>Order page</li>
                  </ul>
                  <br/>
                  <h2>
                    <b> <i> Tech Stack : </i></b> 
                    HTML | CSS | Javascript | Git | Json-server
                  </h2>
                  <br/>
                  <h2>
                   <b> <i> Area of Responsibility :</i></b> 
                    Worked on the camera product page and created it up to full checkout page including cart page and wishlist functionality also. 
                  </h2>
                  <br/>
                </p>

                <div className={styles.buttons}>
                  <a
                    href="https://github.com/krishnachaturvedi1901/reliance_digital_clone"
                    target="_blank"
                    className={styles.primaryBtn}
                  >
                    Github
                  </a>
                  <a
                    href="https://reliancedigitalclone.netlify.app"
                    target="_blank"
                    className={styles.primaryBtn}
                  >
                    Preview{" "}
                  </a>
                  <a
                    href="https://krishnachaturvedi.hashnode.dev/reliance-digital-website-clone"
                    target="_blank"
                    className={styles.primaryBtn}
                  >
                    Blog{" "}
                  </a>

                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
};
